<template>
  <div class="row">
    <div class="col-12 wrapper-box-fiche" v-if="data.prix_italy">   

        <div class="box-white-fiche" v-if="data.prix_italy.prix_italy_price.length > 0 ">
            <table class="comparateur2 border-top-gray" v-if="data.prix_italy.prix_italy_price.length > 0">
                <thead>
                    <tr>
                        <th class="gray" width="42%"><strong> {{$t('Indication')}} </strong></th>
                        <th class="gray" width="12%"><strong> {{$t('Manufacturer selling price (exc. VAT, €)')}} </strong></th>
                        <th class="gray" width="12%"><strong> {{$t('Public price (inc. VAT, €)')}} </strong></th>
                        <th class="gray" width="12%"><strong> {{$t('Publication date')}} </strong></th>
                        <th class="gray" width="12%"><strong> {{$t('Reimbursement class')}} </strong></th>
                    </tr>
                </thead>
                <tr v-for="(value, key) in data.prix_italy.prix_italy_price" :key="'prix_spain_mssi_'+key">
                    <td> {{ value['type_of'] ? value['type_of'] : '-'}}</td>
                    <td> {{ value['factory_price'] ? format_french_number(value['factory_price']) : '-' }} </td>
                    <td> {{ value['retail_price'] ? format_french_number(value['retail_price']) : '-'}} </td>
                    <td> {{ value['publication_date'] ? DDMMMYY(value['publication_date'])  : '-' }} </td>
                    <td> {{ value['eligibility']? value['eligibility'] : '-' }} </td>
                </tr>
            </table>
        </div>
    </div> 
</div>
</template>

<script>
import { format_french_number, DDMMMYY  } from '../../../utils';  

export default {
    name : 'PrixItaly',
    computed : {
        data() {
            return this.$store.getters['detail/data']
        }
    },
    methods : {
        format_french_number,
        DDMMMYY
    }
}
</script>

<style>

</style>